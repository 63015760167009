<template>
  <section class="all-cases">
    <MainPageCaseSection
      v-for="section in sectionsBeforeDaily"
      :key="section.id"
      :anchor="getAnchor(section.id)"
      :title-options="section.titleOptions"
      :cases="section.cases"
      :colors-options="section.colorsOptions"
      :section-banner="changeXL(banners[section.id]?.xl, banners[section.id]?.m)"
      :line-breaks="section.lineBreaks"
      :event-data="{
        color: eventStore.coinColor,
        icon: eventStore.coinIcon,
      }"
      :is-event="section.isEvent"
    />
    <DailyCasesController v-if="!hideFreeCasesBanner" />
    <BattlesAdvController />
    <MainPageCaseSection
      v-for="section in sectionsAfterDaily"
      :key="section.id"
      :anchor="getAnchor(section.id)"
      :title-options="section.titleOptions"
      :cases="section.cases"
      :colors-options="section.colorsOptions"
      :line-breaks="section.lineBreaks"
      :section-banner="changeXL(banners[section.id]?.xl, banners[section.id]?.m)"
      :event-data="{
        color: eventStore.coinColor,
        icon: eventStore.coinIcon,
      }"
      :is-event="section.isEvent"
    />
  </section>
</template>

<script setup lang="ts">
import { banners, SECTIONS_BEFORE_DAILY } from './AllCasesController.data';

import DailyCasesController from '~/features/mainPage/controllers/DailyCasesController/DailyCasesController.vue';
import BattlesAdvController from '~/features/mainPage/controllers/BattlesAdvController/BattlesAdvController.vue';
import { useEventStore } from '~/store/event/event.store';
import { useAllCasesStore } from '~/features/mainPage/store/allCases.store';
import { useEventCasesStore } from '~/features/mainPage/store/eventCases.store';
import { useCoinCasesStore } from '~/features/mainPage/store/coinCases.store';

const { hideFreeCasesBanner } = useProjectSettings();

const eventStore = useEventStore();
const allCasesStore = useAllCasesStore();

const eventCasesStore = useEventCasesStore();
const { section: eventSection } = storeToRefs(eventCasesStore);

const coinsCasesStore = useCoinCasesStore();
const { section: coinsSection } = storeToRefs(coinsCasesStore);

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const eventSectionId = computed(() => eventSection.value?.id);
const coinsSectionId = computed(() => coinsSection.value?.id);

const sectionsBeforeDaily = computed(() => allCasesStore.sections.slice(0, SECTIONS_BEFORE_DAILY));
const sectionsAfterDaily = computed(() => allCasesStore.sections.slice(SECTIONS_BEFORE_DAILY));

const getAnchor = (id: number) => {
  if (id === eventSectionId.value) return 'event-cases';
  if (id === coinsSectionId.value) return 'coin-cases';
  return undefined;
};
</script>

<style scoped lang="scss" src="./AllCasesController.scss" />
